:root {
  --mainColor: rgb(21, 32, 43, 0.6);
  --secondaryColor: rgb(25, 39, 52, 0.5);

  --borderColor: rgb(22, 77, 86);

  /* --mainText: white;
  --secondaryText: rgb(238, 238, 238); */

  --themeDotBorder: white;

  --previewBg: rgb(25, 39, 52, 0.5);
  --previewShadow: rgb(17, 25, 33, 0.6);

  --buttonColor: rgb(23, 162, 184, 0.8);
}

.s1 {
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  padding: 5rem;
  overflow: auto;
}

.main-container {
  width: auto;
  margin: 0 auto;
}

.intro-wrapper {
  border: 1px solid var(--borderColor);
  border-radius: 5px 5px 0 0;

  -webkit-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "nav-wrapper nav-wrapper"
    "left-column right-column";
}

.nav-wrapper {
  border-radius: 5px 5px 0 0;
  grid-area: nav-wrapper;
  border-bottom: 1px solid var(--borderColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--mainColor);
}

.dots-wrapper {
  display: flex;
  padding: 10px;
}

#dot-1 {
  background-color: #fc6058;
}

#dot-2 {
  background-color: #fec02f;
}

#dot-3 {
  background-color: #2aca3e;
}

.browser-dot {
  background-color: black;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin: 5px;

  -webkit-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.75);
}

.left-column {
  grid-area: left-column;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.left-column > h2 {
  padding: 0px;
  margin: 0px;
  margin-top: 20px;
}

.right-column {
  grid-area: right-column;
  display: grid;
  align-content: center;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-right: 20%;
}

.desloc {
  display: flex;
  flex-flow: space-between;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

.social a {
  padding: 10px;
  font-size: calc(10px + 2vmin);
}

#preview-shadow {
  background-color: var(--previewShadow);
  max-width: auto;
  height: auto;
  padding-left: 30px;
  padding-top: 30px;
}

#preview {
  width: auto;
  border: 1.5px solid #17a2b8;
  background-color: var(--previewBg);
  padding: 15px;
  position: relative;
}

.corner {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  border: 1.5px solid #17a2b8;
  background-color: #fff;
  position: absolute;
}

#corner-tl {
  top: -5px;
  left: -5px;
}

#corner-tr {
  top: -5px;
  right: -5px;
}

#corner-br {
  bottom: -5px;
  right: -5px;
}

#corner-bl {
  bottom: -5px;
  left: -5px;
}

.about-wrapper {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); */
  padding-bottom: 50px;
  padding-top: 50px;
  gap: 100px;
}

@media screen and (max-width: 1200px) {
  .main-container {
    width: 95%;
  }
}

@media screen and (max-width: 800px) {
  .intro-wrapper {
    grid-template-columns: 1fr;
    grid-template-areas:
      "nav-wrapper"
      "left-column"
      "right-column";
  }

  .right-column {
    justify-content: center;
    margin-right: 0;
    padding-left: 20px;
  }
}

@media screen and (max-width: 600px) {
  .s1 {
    padding: 2.8rem;
  }
}


@media screen and (max-width: 370px) {
  #preview-shadow {
    max-width: 240px;
    height: 270px;
    padding-left: 10px;
    padding-top: 10px;
  }
  #preview {
    width: 230px;
  }
  .s1 {
    padding: 2rem;
  }
  .right-column {
    justify-content: left;
    margin: 0;
    padding-left: 7px;
  }
}
